import "../styles/ProductPage.scss";

import { document, window } from "browser-monads";

// import Footer from "../components/Footer.jsx";
import Helmet from "react-helmet";
import Logo from "../components/Logo.jsx";
import React from "react";

function ProductPage({ pageContext }) {
  const id = pageContext.bigwaxId;
  const titre = pageContext.titre;

  return (
    <div className="productpage-container">
      <Helmet
        title={titre + " | Bon Entendeur shop"}
        htmlAttributes={{ lang: "fr" }}
      />
      <div className="logo-product-container">
        <Logo currentPage="productPage" />
      </div>

      {/* IFRAME BIGWAX */}
      <div id="product-component"></div>
      {(function () {
        var scriptURL =
          "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
        if (window.ShopifyBuy) {
          if (window.ShopifyBuy.UI) {
            ShopifyBuyInit();
          } else {
            loadScript();
          }
        } else {
          loadScript();
        }
        function loadScript() {
          var script = document.createElement("script");
          script.async = true;
          script.src = scriptURL;
          (
            document.getElementsByTagName("head")[0] ||
            document.getElementsByTagName("body")[0]
          ).appendChild(script);

          script.onload = ShopifyBuyInit;
        }
        function ShopifyBuyInit() {
          var client = window.ShopifyBuy.buildClient({
            domain: "bigwax.io",
            storefrontAccessToken: "31682f9bb9f4efdfdcfd96fb08af4c27",
          });
          window.ShopifyBuy.UI.onReady(client).then(function (ui) {
            ui.createComponent("product", {
              id: id,
              node: document.getElementById("product-component"),
              moneyFormat: "%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D",
              options: {
                product: {
                  iframe: false,
                  styles: {
                    product: {
                      "font-family": "Nunito",
                      "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0",
                        "margin-bottom": "50px",
                      },
                      "text-align": "left",
                    },
                    title: {
                      "font-family": "Nunito",
                      "font-weight": "normal",
                      "font-size": "26px",
                      color: "#000000",
                    },
                    button: {
                      "font-family": "Nunito",
                      "font-weight": "bold",
                      "font-size": "13px",
                      "padding-top": "14.5px",
                      "padding-bottom": "14.5px",
                      ":hover": {
                        "background-color": "#0d68e6",
                      },
                      "background-color": "#0e73ff",
                      ":focus": {
                        "background-color": "#0d68e6",
                      },
                      "border-radius": "40px",
                      "padding-left": "37px",
                      "padding-right": "37px",
                    },
                    quantityInput: {
                      "font-family": "Nunito",
                      "font-size": "13px",
                      "padding-top": "14.5px",
                      "padding-bottom": "14.5px",
                    },
                    price: {
                      "font-family": "Nunito",
                      "font-size": "18px",
                      color: "#000000",
                    },
                    compareAt: {
                      "font-family": "Nunito",
                      "font-size": "15.299999999999999px",
                      color: "#000000",
                    },
                    unitPrice: {
                      "font-family": "Nunito",
                      "font-size": "15.299999999999999px",
                      color: "#000000",
                    },
                    description: {
                      color: "#000000",
                    },
                  },
                  layout: "horizontal",
                  contents: {
                    img: false,
                    imgWithCarousel: true,
                    description: true,
                  },
                  width: "100%",
                  text: {
                    button: "Commander",
                  },
                },
                productSet: {
                  styles: {
                    products: {
                      "@media (min-width: 601px)": {
                        "margin-left": "-20px",
                      },
                    },
                  },
                },
                modalProduct: {
                  contents: {
                    img: false,
                    imgWithCarousel: true,
                  },
                  styles: {
                    product: {
                      "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px",
                      },
                    },
                    button: {
                      "font-family": "Nunito",
                      "font-weight": "bold",
                      "font-size": "13px",
                      "padding-top": "14.5px",
                      "padding-bottom": "14.5px",
                      ":hover": {
                        "background-color": "#0d68e6",
                      },
                      "background-color": "#0e73ff",
                      ":focus": {
                        "background-color": "#0d68e6",
                      },
                      "border-radius": "40px",
                      "padding-left": "37px",
                      "padding-right": "37px",
                    },
                    quantityInput: {
                      "font-family": "Nunito",
                      "font-size": "13px",
                      "padding-top": "14.5px",
                      "padding-bottom": "14.5px",
                    },
                    title: {
                      "font-family": "Nunito",
                      "font-size": "28px",
                      color: "#ffffff",
                    },
                    price: {
                      "font-family": "Nunito",
                      "font-size": "14px",
                      color: "#ffffff",
                    },
                    compareAt: {
                      "font-family": "Nunito",
                      "font-size": "11.9px",
                      color: "#ffffff",
                    },
                    unitPrice: {
                      "font-family": "Nunito",
                      "font-size": "11.9px",
                      color: "#ffffff",
                    },
                    description: {
                      color: "#ffffff",
                    },
                  },
                  text: {
                    button: "Add to cart",
                  },
                },
                modal: {
                  styles: {
                    modal: {
                      "background-color": "#000000",
                    },
                  },
                },
                option: {
                  styles: {
                    label: {
                      color: "#000000",
                    },
                  },
                },
                cart: {
                  iframe: true,
                  styles: {
                    button: {
                      "font-family": "Nunito",
                      "font-weight": "bold",
                      "font-size": "13px",
                      "padding-top": "14.5px",
                      "padding-bottom": "14.5px",
                      ":hover": {
                        "background-color": "#0d68e6",
                      },
                      "background-color": "#0e73ff",
                      ":focus": {
                        "background-color": "#0d68e6",
                      },
                      "border-radius": "40px",
                    },
                    title: {
                      color: "#000000",
                    },
                    header: {
                      color: "#000000",
                    },
                    lineItems: {
                      color: "#000000",
                    },
                    subtotalText: {
                      color: "#000000",
                    },
                    subtotal: {
                      color: "#000000",
                    },
                    notice: {
                      color: "#000000",
                    },
                    currency: {
                      color: "#000000",
                    },
                    close: {
                      color: "#000000",
                      ":hover": {
                        color: "#000000",
                      },
                    },
                    empty: {
                      color: "#000000",
                    },
                    noteDescription: {
                      color: "#000000",
                    },
                    discountText: {
                      color: "#000000",
                    },
                    discountIcon: {
                      fill: "#000000",
                    },
                    discountAmount: {
                      color: "#000000",
                    },
                  },
                  text: {
                    title: "CART",
                    notice: "",
                  },
                },
                toggle: {
                  styles: {
                    toggle: {
                      "font-family": "Nunito",
                      "font-weight": "bold",
                      "background-color": "#0e73ff",
                      ":hover": {
                        "background-color": "#0d68e6",
                      },
                      ":focus": {
                        "background-color": "#0d68e6",
                      },
                    },
                    count: {
                      "font-family": "Nunito",
                      "font-size": "13px",
                    },
                  },
                },
                lineItem: {
                  styles: {
                    variantTitle: {
                      color: "#000000",
                    },
                    title: {
                      color: "#000000",
                    },
                    price: {
                      color: "#000000",
                    },
                    fullPrice: {
                      color: "#000000",
                    },
                    discount: {
                      color: "#000000",
                    },
                    discountIcon: {
                      fill: "#000000",
                    },
                    quantity: {
                      color: "#000000",
                    },
                    quantityIncrement: {
                      color: "#000000",
                      "border-color": "#000000",
                    },
                    quantityDecrement: {
                      color: "#000000",
                      "border-color": "#000000",
                    },
                    quantityInput: {
                      color: "#000000",
                      "border-color": "#000000",
                    },
                  },
                },
              },
            });
          });
        }
      })()}
      {/* <Footer /> */}
    </div>
  );
}

export default ProductPage;
